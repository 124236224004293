import React from 'react'
import styled from 'styled-components'

import { times, sizes, colors } from '../styles'
const transitionLength = times.med
const Wrap = styled.main`
	/* transition: ${props =>
		props.status === 'entering' ? 'none' : `all ${transitionLength}ms ${times.ease}`}; */
  /* transform: ${props =>
		props.status !== 'entered' ? `translate3d(0,50px,0)` : 'translate3d(0,0,0)'}; */

	/* opacity: ${props => (props.status === 'entering' || props.status === 'entered' ? 1 : 0)}; */
	
  /* transition: ${props =>
		props.status === 'exited' ? 'none' : `all ${transitionLength}ms ${times.ease}`}; */

  transition: all ${transitionLength}ms ${times.ease};
  transform: ${props =>
		props.status === 'exited' || props.status === 'entering'
			? 'translate3d(100%, 0, 0)'
			: 'translate3d(0,0,0)'};

	padding: 5vh 0;
	padding-right: ${sizes.gutterMobile};
	padding-left: 20vw;
	flex-grow: 1;
	min-height: 90vh;
  background: ${colors.white};
  @media only screen and (min-width: ${sizes.tablet}) {
		padding: 5vh ${sizes.gutterFluid};
		margin-left: 15vw;
		/* border-left: solid 1px ${colors.grey}; */
    box-shadow: ${props => (props.status === 'entered' ? `5px -20px 50px ${colors.grey}` : 'none')};
	}
	@media only screen and (min-width: ${sizes.sdesk}) {
		margin-left: 30vw;
	}
`

const PageLayout = ({ transitionStatus, children, path, ...page }) => {
	console.log(`${path} transition status: ${transitionStatus} |`, page)

	return (
		<Wrap className='page-content' status={transitionStatus}>
			{children}
		</Wrap>
	)
}
export default React.memo(PageLayout)
